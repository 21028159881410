import { Col, Row, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import { AiOutlineControl, AiOutlineDatabase } from "react-icons/ai";
import { LiaUsersSolid } from "react-icons/lia";
import { BsPatchCheck } from "react-icons/bs";
import { FaCubes, FaPencilRuler, FaRegAddressBook, FaRegFolderOpen, FaTasks, FaUser } from "react-icons/fa";
import { TiArrowShuffle } from "react-icons/ti";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { IoMdListBox } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import CollapseCard from "../../components/Template/Card";
import Layout from "../../components/Template/Layout";
import api from "../../services/Api";
import "./homePage.scss";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { FaCheckToSlot } from "react-icons/fa6";
import { SettingOutlined } from "@ant-design/icons";


const { Text } = Typography;

function HomePage() {
  const navigate = useNavigate();
  const [roles, setRoles] = useState<string[]>([]);
  const hired_id = localStorage.getItem("user_hired");
  useEffect(() => {
    api.get(`/v1/user/roles`).then((response) => {
      if (response.status === 200) {
        const data = response.data;
        setRoles(data.roles);
      }
    });
  }, []);

  return (
    <>
      <Layout pageTitle="Home Page" breadcrumb="Home" cantGoBack>
        <div className="mb-2">
          <CollapseCard title="Acesso Rápido">
            <Row align={"middle"} style={{ justifyContent: "center" }}>
              {roles.length > 0 ? (
                <>
                  {roles.length > 0 && ["Administrador"].some((item) => roles[0].includes(item)) && (
                    <Col className="dashboard-menu" onClick={() => navigate("/users")}>
                      <Col className="text-center">
                        <LiaUsersSolid size={"4em"} />
                      </Col>
                      <Col className="text-center">
                        <Text>Usuários</Text>
                      </Col>
                    </Col>
                  )}
                  <Col id="home-projects" className="dashboard-menu" onClick={() => navigate("/projects")}>
                    <Col className="text-center">
                      <AiOutlineDatabase size={"4em"} />
                    </Col>
                    <Col className="text-center">
                      <Text>Projetos</Text>
                    </Col>
                  </Col>

                  <Col id="home-measures" className="dashboard-menu" onClick={() => navigate("/requisitions")}>
                    <Col className="text-center">
                      <IoMdListBox size={"4em"} />
                    </Col>
                    <Col className="text-center">
                      <Text>Requisições</Text>
                    </Col>
                  </Col>
                  <Col id="home-measures" className="dashboard-menu" onClick={() => navigate("/report")}>
                    <Col className="text-center">
                      <HiOutlineDocumentReport size={"4em"} />
                    </Col>
                    <Col className="text-center">
                      <Text>Relatórios</Text>
                    </Col>
                  </Col>

                  <Col className="dashboard-menu" onClick={() => navigate("/measures")}>
                    <Col className="text-center">
                      <FaPencilRuler size={"4em"} />
                    </Col>
                    <Col className="text-center">
                      <Text>Medições</Text>
                    </Col>
                  </Col>

                  <Col id="home-contracts" className="dashboard-menu" onClick={() => navigate("/contracts")}>
                    <Col className="text-center">
                      <FaRegFolderOpen size={"4em"} />
                    </Col>
                    <Col className="text-center">
                      <Text>Contratos</Text>
                    </Col>
                  </Col>

                  <Col id="home-activities" className="dashboard-menu" onClick={() => navigate("/activities")}>
                    <Col className="text-center">
                      <FaTasks size={"4em"} />
                    </Col>
                    <Col className="text-center">
                      <Text>Atividades</Text>
                    </Col>
                  </Col>
                  <Col id="home-contracts" className="dashboard-menu" onClick={() => navigate("/activity-flow")}>
                    <Col className="text-center">
                      <TiArrowShuffle size={"4.5em"} />
                    </Col>
                    <Col className="text-center">
                      <Text>Fluxo</Text>
                    </Col>
                  </Col>
                  <Col id="home-contracts" className="dashboard-menu" onClick={() => navigate("/measurement-bulletin")}>
                    <Col className="text-center">
                      <MdOutlineCalendarMonth size={"4.5em"} />
                    </Col>
                    <Col className="text-center">
                      <Text>Boletins</Text>
                    </Col>
                  </Col>

                  {roles.length > 0 && ["Administrador", "Owner", "Subowner"].some((item) => roles[0].includes(item)) ? (
                    <Col id="home-hireds" className="dashboard-menu" onClick={() => navigate("/hireds")}>
                      <Col className="text-center">
                        <FaUser size={"4em"} />
                      </Col>
                      <Col className="text-center">
                        <Text>Contratadas</Text>
                      </Col>
                    </Col>
                  ) : null}
                  {roles.length > 0 && ["Hired"].some((item) => roles[0].includes(item)) ? (
                    <Col id="home-hireds" className="dashboard-menu" onClick={() => navigate(`/hireds/${hired_id}/activities`)}>
                      <Col className="text-center">
                        <FaRegAddressBook size={"4em"} />
                      </Col>
                      <Col className="text-center">
                        <Text>Dashboard</Text>
                      </Col>
                    </Col>
                  ) : null}
                  {roles.length > 0 &&
                    (["Administrador", "Owner"].some((item) => roles[0].includes(item)) || roles[0].includes("Subowner_quality")) ? (
                    <Col id="home-hireds" className="dashboard-menu" onClick={() => navigate("/quality")}>
                      <Col className="text-center">
                        <BsPatchCheck size={"4em"} />
                      </Col>
                      <Col className="text-center">
                        <Text>Qualidade</Text>
                      </Col>
                    </Col>
                  ) : null}
                  {roles.length > 0 &&
                    (["Administrador", "Owner"].some((item) => roles[0].includes(item)) || roles[0].includes("Subowner_control")) ? (
                    <Col id="home-hireds" className="dashboard-menu" onClick={() => navigate("/control")}>
                      <Col className="text-center">
                        <AiOutlineControl size={"4em"} />
                      </Col>
                      <Col className="text-center">
                        <Text>Controle</Text>
                      </Col>
                    </Col>
                  ) : null}

                  <Col id="home-validation" className="dashboard-menu" onClick={() => navigate("/validations")}>
                    <Col className="text-center">
                      <FaCheckToSlot size={"4em"} />
                    </Col>
                    <Col className="text-center">
                      <Text>Validações</Text>
                    </Col>
                  </Col>

                  <Col id="home-validation" className="dashboard-menu" onClick={() => navigate("/batch-advance")}>
                    <Col className="text-center">
                      <FaCubes size={"4em"} />
                    </Col>
                    <Col className="text-center">
                      <Text>Medições em massa</Text>
                    </Col>
                  </Col>
          
                  
                  {roles.length > 0 && ["Administrador"].some((item) => roles[0].includes(item)) && (
                    <Col id="home-settings" className="dashboard-menu" onClick={() => navigate("/settings")}>
                      <Col className="text-center">
                         <SettingOutlined style={{ fontSize: "4em" }} />
                      </Col>
                      <Col className="text-center" style={{ margin: '4px 0 -1px 0' }}>
                        <Text>Configurações</Text>
                      </Col>
                    </Col>
                  )}

                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    padding: 24,
                  }}
                >
                  <Spin />
                </div>
              )}
            </Row>
          </CollapseCard>
        </div>
      </Layout>
    </>
  );
}

export default HomePage;
