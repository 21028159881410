import React from "react";
import { List, Typography } from "antd";
import "../BatchAdvance.scss";

const { Title, Text } = Typography;

type UpdateBankProps = {
  selectedRows: any[];
};

const UpdateBank: React.FC<UpdateBankProps> = ({ selectedRows }) => {
  const getNextMeasurementMessage = (dbPercent: number): string => {
    switch (dbPercent) {
      case 50:
        return "Próxima medição mínima: 75%";
      case 75:
        return "Próxima medição mínima: 90%";
      case 90:
        return "Próxima medição mínima: 100%";
      default:
        return "Valor inválido ou medição já está completa.";
    }
  };

  return (
    <div className="update-bank-container">
      <Title level={3}>Confirmação de avanço físico</Title>
      <Text className="update-text">Itens selecionados para atualização:</Text>

      <List
        className="selected-items-list"
        bordered
        dataSource={selectedRows}
        renderItem={(row: any) => {
          const showMessage = row.dbPercent === parseFloat(row.excelPercent);
          const nextMeasurementMessage = getNextMeasurementMessage(row.dbPercent);

          return (
            <>
              <List.Item className="selected-item">
                <div className="item-content">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Text>
                      <span className="text-label">Step:</span>{" "}
                      <span className="text-label-row">{row.step}</span>
                      <span className="text-label">de:</span>{" "}
                      <span className="text-label-row">{row.dbPercent}%</span>
                      <span className="text-label">para:</span>{" "}
                      <span className="text-label-row">{row.excelPercent}%</span>
                    </Text>
                  {showMessage && (
                    <Text type="danger" style={{ marginTop: "8px", display: "block" }}>
                      Valor da medição precisa ser maior que o atual. {nextMeasurementMessage}
                    </Text>
                  )}
                  </div>
                </div>
              </List.Item>
            </>
          );
        }}
      />
    </div>
  );
};

export default UpdateBank;
