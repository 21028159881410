import { useEffect } from "react";
import { Button, Modal } from "antd";
import { useFetchUser } from "../../fetch/useFetchUser";
import { useFetchRoles } from "../../../../hooks/roles/useFetchRoles";
import { CreateUserFormData, createUserSchema } from "../../validation/createUserSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import FormInput from "../../../../components/FormComponents/FormInput";
import FormSelect from "../../../../components/FormComponents/SelectInput";
import { EditUserFormData, editUserSchema } from "../../validation/editUserSchema";
import { useFetchTenants } from "../../../../hooks/tenants/useFetchTenants";
import { useCreateUser } from "../../fetch/useCreateUser";
import { useUpdateUser } from "../../fetch/useUpdateUser";

type Props = {
  isModalOpen: boolean;
  onClose: () => void;
  id?: number;
};

export default function ModalCreateEditUser({ isModalOpen, onClose, id }: Props) {
  const { user } = useFetchUser(id);
  const { roles } = useFetchRoles();
  const { tenants } = useFetchTenants();
  const { createUser, isCreating } = useCreateUser();
  const { updateUser, isUpdating } = useUpdateUser();

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<CreateUserFormData | EditUserFormData>({
    resolver: zodResolver(!!id ? editUserSchema : createUserSchema),
    defaultValues: {
      name: "",
      email: "",
      password: "",
      role: "",
    },
  });

  useEffect(() => {
    if (user) {
      reset({
        name: user.name || "",
        email: user.email || "",
        password: "", // Mantém a senha vazia por segurança
        role: user.role[0].id ? user.role[0].id.toString() : "",
        tenant: user.tenant ? user.tenant.id.toString() : "",
      });
    }
  }, [user, reset]);

  const onSubmit = async (data: CreateUserFormData | EditUserFormData) => {
    if (id) {
      await updateUser(id, data);
    } else {
      await createUser(data as CreateUserFormData);
    }

    onClose(); // Fecha o modal após a ação
  };

  return (
    <Modal title={`${id ? "Editar" : "Criar"} usuário`} open={isModalOpen} onOk={onClose} onCancel={onClose} footer={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput name="name" label="Nome" control={control} placeholder="Digite o nome" errors={errors} />
        <FormInput name="email" label="E-mail" control={control} placeholder="Digite o e-mail" type="email" errors={errors} />
        {!id && <FormInput name="password" label="Senha" control={control} placeholder="Digite a senha" type="password" errors={errors} />}
        <FormSelect
          name="role"
          label="Role"
          control={control}
          options={roles.map((item: any) => ({ value: item.id.toString(), label: item.name }))}
          placeholder="Selecione uma role"
          errors={errors}
        />
        <FormSelect
          name="tenant"
          label="Tenant"
          control={control}
          options={tenants.map((item: any) => ({ value: item.id.toString(), label: item.name }))}
          placeholder="Selecione uma role"
          errors={errors}
        />
        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "24px" }}>
          <Button key="back" onClick={onClose}>
            Cancelar
          </Button>
          <Button type="primary" disabled={isSubmitting} onClick={handleSubmit(onSubmit)}>
            {id ? "Editar" : "Criar"} usuário
          </Button>
        </div>
      </form>
    </Modal>
  );
}
