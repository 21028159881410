import { useCallback, useEffect, useState } from "react";
import api from "../../config/axios";
import Report from "../../models/Report";
import returnStatusMeasure from "../../utils/returnStatusMeasure";
import { returnDatereport } from "../../utils/returnDateReport";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { ReturnCost } from "../../pages/ReportPage/utils/ReturnCost";

type Props = {
  page: number;
  refresh?: Date;
  startDate?: string;
  endDate?: string;
  cwa: number | string;
  project: number | string;
  status: number | string;
  hired: string;
  contract: string;
  bulletins?: boolean;
  bulletin_id?: string;
};

export const useFetchReport = ({ page, refresh, status, startDate, endDate, cwa, project, hired, contract, bulletins, bulletin_id }: Props) => {
  const [report, setReport] = useState([]);
  const [cwas, setCwas] = useState([]);
  const [projects, setProjects] = useState([]);
  const [hireds, setHireds] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [totals, setTotals] = useState({
    total_consumed: 0,
    total_approved: 0,
    total_reproved: 0,
  });
  const [total, setTotal] = useState(0);
  const [totalBm, setTotalBm] = useState<any>(0);
  const [isFetching, setIsFetching] = useState(true);
  const navigate = useNavigate();
  const returnReport = useCallback(() => {
    setIsFetching(true);
    setReport([]);
    api
      .get(
        `/v1/${!!bulletins ? "measurement-bulletins" : "report"}${!!bulletins ? `/${bulletin_id}` : ""}?startDate=${
          !!startDate ? startDate : ""
        }&endDate=${!!endDate ? endDate : ""}&page=${page}&cwa=${cwa}&project=${project}&status=${status}&hired=${hired}&contract=${contract}`
      )
      .then((response) => {
       
   
        const data = response.data.data.activities.data;
     
        setCwas(response.data.cwas);
        setProjects(response.data.projects);
        setHireds(response.data.hireds);
        setContracts(response.data.contracts);

        const table = data.map((obj: Report) => ({
          ...obj,
          key: obj.id,
          status: returnStatusMeasure(obj),
          date: returnDatereport(obj),
          rejected_cost: ReturnCost(obj, "rejected"),
          cost: ReturnCost(obj, "approved"),
          actions: (
            <>
              <Button type="primary" onClick={() => navigate("/activity/" + obj.activity_id)}>
                Ir para atividade
              </Button>
            </>
          ),
        }));
        const totals = table.reduce(
          (acc: any, obj: Report) => {
            acc.total_presenter += +obj.calculated_cost;
            acc.total_approved += obj.cost ? +obj.cost : 0;
            acc.total_reproved += obj.rejected_cost ? +obj.rejected_cost : 0;
            return acc;
          },
          { total_presenter: 0, total_approved: 0, total_reproved: 0 }
        );

        setTotalBm(totals);

        setReport(table);
        setTotal(response.data.total);
        setTotals({
          total_consumed: response.data.totals.total_consumed,
          total_approved: response.data.totals.total_approved,
          total_reproved: response.data.totals.total_reproved,
        });
      })
      .catch((err) => {})
      .finally(() => {
        setIsFetching(false);
      });
  }, [page, startDate, endDate, cwa, project, contract, hired, status, refresh]);

  useEffect(() => {
    returnReport();
  }, [returnReport]);

  return {
    report,
    cwas,
    projects,
    isFetching,
    total,
    hireds,
    contracts,
    totals,
    totalBm,
  };
};
