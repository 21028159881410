export   const calculateAppliedMeasurement = (percentComplete: string | null) => {
    if (!percentComplete) return "";
  
    const percentCompleteValue = parseFloat(percentComplete);
    let appliedMeasurement = "";
  
    if (percentCompleteValue >= 50 && percentCompleteValue <= 74.99) {
      appliedMeasurement = "50";
    } else if (percentCompleteValue >= 75 && percentCompleteValue <= 89.99) {
      appliedMeasurement = "75";
    } else if (percentCompleteValue >= 90 && percentCompleteValue <= 99.99) {
      appliedMeasurement = "90";
    } else if (percentCompleteValue === 100) {
      appliedMeasurement = "100";
    }
  
    return appliedMeasurement;
  };
  