import { useCallback, useEffect, useState } from "react";
import api from "../../../config/axios";
import { User } from "../../../models/User";

export const useFetchUser = (id?: number) => {
  const [user, setUser] = useState<User | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchUser = useCallback(() => {
    setIsFetching(true);
    api
      .get(`/v1/users/${id}`)
      .then((response) => {
        setUser(response.data.user);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchUser();
    }
  }, [fetchUser, id]);

  return {
    user,
    isFetching,
    error,
  };
};
