import { z } from "zod";

export const createUserSchema = z.object({
  name: z.string({ required_error: "O nome é obrigatório." }).min(1, { message: "O nome é obrigatório." }),
  email: z.string({ required_error: `E-mail é obrigatório` }).email("Digite um e-mail válido."),
  password: z
    .string({ required_error: "A senha deve ter pelo menos 6 caracteres." })
    .min(6, { message: "A senha deve ter pelo menos 6 caracteres." }),
  role: z.string({ required_error: "Selecione uma role válida." }).min(1, { message: "Selecione uma role válida." }),
  tenant: z.string({ required_error: "Selecione um tenant válido." }).min(1, { message: "Selecione um tenant válido." }),
});
export type CreateUserFormData = z.infer<typeof createUserSchema>;
