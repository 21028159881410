import { Key, ReactNode, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PaginationProps, Spin, Typography, Table, Col, Row, Pagination, ConfigProvider } from "antd";
import ModalPartialMesurement from "../../components/ActivityView/components/ModalPartialMesurement/ModalPartialMeaurement";
import MeasureModal from "../../components/CwaMeasureModal/modal";
import { usePostMeasurementPartial } from "../../hooks/measure/usePostMeasurementPartial";
import api from "../../services/Api";
import Layout from "../../components/Template/Layout";
import CollapseCard from "../../components/Template/Card";
import SearchInput from "../../components/SearchInput";
import { TableType } from "../../types/cwa";
import { ExpandedTable2, ExpandedTable3, ExpandedTable4, ExpandedTable5 } from "./components/expandedRows";
import "./cwaPage.scss";
import { mainTableColumns, mainTableHiredColumns } from "./utils/columns";
import {
  setExpandedData1,
  setExpandedData2,
  setExpandedData3,
  setExpandedDataIWP,
  setMainTableData,
  setMainTableDataWithSearch,
} from "./utils/setTableData";

const { Title } = Typography;

function CwaPage() {
  const [tables, setTables] = useState<TableType>({
    table1: { data: [], isLoading: true, isLoadingId: null, total: 0 },
    table2: { data: [], isLoading: true, isLoadingId: null, total: 0 },
    table3: { data: [], isLoading: true, isLoadingId: null, total: 0 },
    table4: { data: [], isLoading: true, isLoadingId: null, total: 0 },
    table5: { data: [], isLoading: true, isLoadingId: null, total: 0 },
  });

  const [roles, setRoles] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { project_id } = useParams();
  const [projectName, setProjectName] = useState(undefined);
  const [selectedPage, setSelectedPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [currentKeys, setCurrentKeys] = useState<any[]>([]);
  const navigate = useNavigate();
  const canNavigate = roles.length > 0 && ["Administrador", "Owner", "Subowner"].some((item) => roles[0].includes(item));

  const { postMeasurementPartial } = usePostMeasurementPartial();

  // Search handlers
  const [search, setSearch] = useState("");

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleSearch = (search: string) => {
    setMainTableDataWithSearch({
      setTables,
      project_id,
      search,
      selectedPage,
      navigate,
      setPageSize,
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleClose = () => {
    setIsOpen(false);
    setSelectedActivity(null);
  };

  const openModal = async (activity: any) => {
    const response = await api.get(`/v1/activity/${activity.id}`);
    if (response.status === 200) {
      setSelectedActivity(response.data);
      setIsOpen(true);
    }
  }

  // Expanded row rendering
  const expandedRowRender = (cwa: any): ReactNode => {
      const expandedRowRender = (wp: any): ReactNode => {
        const expandedRowRender = (activityType: any): ReactNode => {
          const expandedRowRender = (activityType: any): ReactNode => {
    return activityType.unique ? null : (
      <>
        <ExpandedTable5
          table={tables.table5}
          expandedRowRender={expandedRowRender}
          record={activityType}
          canNavigate={canNavigate}
          setExpandedDataIWP={({ key }: { key: Key }) =>
            setExpandedDataIWP({ key, setTables, navigate, openModal })
          }
        />
      </>
    );
  };
        return (
          <>
            <ExpandedTable4
              table={tables.table4}
              expandedRowRender={expandedRowRender}
              record={activityType}
              canNavigate={canNavigate}
              setExpandedDataIWP={({ key }: { key: Key }) =>
                setExpandedDataIWP({ key, setTables, navigate, openModal })
              } />
          </>
        );
      };

      return (
        <ExpandedTable3
          table={tables.table3}
          setExpandedData3={({ key }: { key: Key }) =>
            setExpandedData3({ key, setTables, navigate, openModal })         
          }
          expandedRowRender={expandedRowRender}
          record={wp}
        />
      );
    };

    return (
      <>
        <div className="container-expandable">
          <ExpandedTable2
            table={tables.table2}
            setExpandedData1={async ({ key, page }: { key: Key; page?: number | undefined }) =>
              await setExpandedData1({
                key,
                page,
                setTables,
                navigate,
                project_id,
              })
            }
            setExpandedData2={({ key }: { key: Key }) => setExpandedData2({ key, setTables })}
            expandedRowRender={expandedRowRender}
            record={cwa}
            canNavigate={canNavigate}
          />
        </div>
      </>
    );
  };

  useEffect(() => {
    api.get(`/v1/user/roles`).then((response) => {
      if (response.status === 200) {
        const data = response.data;
        setRoles(data.roles);
      }
    });
  }, []);
  useEffect(() => {
    setMainTableData({
      setTables,
      project_id,
      selectedPage,
      navigate,
      setPageSize,
    });
  }, [selectedPage]);

  useEffect(() => {
    if (project_id)
      api.get("/v1/projects/" + project_id).then((response) => {
        if (response.status === 200) {
          const data = response.data;
          setProjectName(data.name);
        }
      });
  }, [project_id]);

  const customizeRenderEmpty = () => <div style={{ textAlign: "center" }}>Sem dados</div>;

  const onChange: PaginationProps["onChange"] = (page) => {
    setSelectedPage(page);
  };

  const refreshSelectedActivity = async () => {
    if (selectedActivity?.id) {
      const response = await api.get(`/v1/activity/${selectedActivity.id}`);
      if (response.status === 200) {
        setSelectedActivity(response.data);
      }
    }
  };


  return (
    <>
      <Layout
        pageTitle="CWA - Áreas do Projeto"
        breadcrumb={
          projectName ? (
            `${projectName} ⟶ CWA`
          ) : (
            <>
              <Spin size="small" className="breadcrumb-loading" /> {" ⟶ CWA"}
            </>
          )
        }
      >
        <Row gutter={16} className="page-row">
          <Col xs={24} md={6} className="mb-2">
            <CollapseCard title="Total">
              {!!tables.table1.total ? (
                <Title id="activities-total" level={2} className="total-card">
                  {tables.table1.total}
                </Title>
              ) : null}
            </CollapseCard>
          </Col>
          <Col xs={24} md={18} className="mb-2">
            <CollapseCard title="Filtros">
              <Col span={24} className="mb-2 activity-filters">
                <div className="filter-measure-container">
                  <div className="container-search">
                    <SearchInput
                      type="text"
                      handleChange={handleChangeSearch}
                      value={search}
                      handleSearch={handleSearch}
                      loading={tables.table1.isLoading}
                      placeholder="Busque por um nome"
                    />
                  </div>
                </div>
              </Col>
            </CollapseCard>
          </Col>
          <Col xs={24} md={24} className="mb-2">
            <CollapseCard title="Tabela de CWA" className="card-table-no-padding">
              <Row>
                <Col span={24} style={{ overflow: "auto" }}>
                  <ConfigProvider renderEmpty={customizeRenderEmpty}>
                    <Col span={24} className="mb-2 text-right">
                      <SearchInput
                        type="text"
                        handleChange={handleChangeSearch}
                        value={search}
                        handleSearch={handleSearch}
                        placeholder="Busque pelo código ou descrição"
                        loading={tables.table1.isLoading}
                        classe="cwa-search"
                      />
                    </Col>

                    <Col span={24} style={{ overflow: "auto" }}>
                      {roles.length > 0 ? (
                        <Table
                          className="table-default-style"
                          columns={canNavigate ? mainTableColumns : mainTableHiredColumns}
                          expandable={{
                            expandedRowRender,
                            onExpandedRowsChange: (keys) => {
                              if (currentKeys.length < keys.length) {
                                setExpandedData1({
                                  key: keys[keys.length - 1],
                                  setTables,
                                  navigate,
                                  project_id,
                                });
                              }
                              setCurrentKeys([...keys]);
                            },
                          }}
                          loading={tables.table1.isLoading}
                          dataSource={tables.table1.data}
                          pagination={false}
                          // scroll={{ y: 350 }}
                          size="small"
                          style={{ minWidth: "700px" }}
                        />
                      ) : (
                        <Table
                          className="table-default-style"
                          columns={mainTableHiredColumns}
                          loading={true}
                          dataSource={[]}
                          pagination={false}
                          size="small"
                          style={{ minWidth: "600px" }}
                        />
                      )}
                    </Col>
                  </ConfigProvider>
                </Col>
              </Row>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "5px",
                }}
              >
                <Pagination current={selectedPage} onChange={onChange} pageSize={pageSize} total={tables.table1.total} />
              </Row>
            </CollapseCard>
            {isModalOpen ? <MeasureModal isModalOpen={isModalOpen} handleCancel={handleCancel} /> : null}
          </Col>
        </Row>
      </Layout>
      {isOpen && (
        <ModalPartialMesurement
          isOpen={isOpen}
          handleClose={async () => {
            await refreshSelectedActivity(); 
            handleClose();
          }}
          submit={async (value) => {
            await postMeasurementPartial({
              activity_id: selectedActivity.id,
              percentage: value.value,
              date: value.date,
            });
            handleClose();
          }}
          activity={selectedActivity}
        />


      )}
    </>
  );
}

export default CwaPage;
