import React from "react";
import { Input } from "antd";
import { Controller } from "react-hook-form";

interface FormInputProps {
  name: string;
  label: string;
  control: any;
  placeholder?: string;
  type?: string;
  errors?: any;
}

const FormInput: React.FC<FormInputProps> = ({ name, label, control, placeholder, type = "text", errors }) => {
  return (
    <div>
      <label htmlFor={name}>{label}</label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => <Input {...field} id={name} placeholder={placeholder} type={type} status={errors?.[name] ? "error" : ""} />}
      />
      {errors?.[name] && <p style={{ color: "red", marginTop: "0px" }}>{errors[name].message}</p>}
    </div>
  );
};

export default FormInput;
