import { TableColumnsType, Tag } from "antd";
import { formatCurrency } from "../../../utils/formatCurrency";
import { TextCropToltip } from "../../../components/TextCropToltip";

import { formatPercentage } from "../../../utils/formatPercentage";

export const columns: TableColumnsType<any> = [
  {
    title: "Nome",
    dataIndex: ["name"],
    key: "name",
    width: 250,
  },
  {
    title: "email",
    dataIndex: ["email"],
    key: "email",
    width: 150,
  },
  {
    title: "Permissão",
    dataIndex: ["role"],
    key: "permission",
    render: (text) => <div>{text?.[0].name || "-"}</div>,
    width: 150,
  },
  {
    title: "",
    dataIndex: "actions",
    key: "actions",
    width: 160,
  },
];

export default columns;
