import { useState } from "react";
import api from "../../../config/axios";
import { toastAlert } from "../../../utils/toastAlert";

interface UseDeleteUserResponse {
  deleteUser: (id: number) => Promise<void>;
  isDeleting: boolean;
  error: Error | null;
}

export const useDeleteUser = (): UseDeleteUserResponse => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const deleteUser = async (id: number) => {
    setIsDeleting(true);
    setError(null);

    try {
      await api.delete(`/v1/users/${id}`);
      toastAlert({
        type: "success",
        message: "O usuário foi excluído com sucesso e já não tem mais acesso ao sistema.",
        title: "Usuário excluído com sucesso.",
      });
    } catch (err: any) {
      toastAlert({
        type: "error",
        message: "Ocorreu um erro ao deletar usuário, tente novamente",
        title: "Erro ao excluir usuário!",
      });
      setError(err);
    } finally {
      setIsDeleting(false);
    }
  };

  return {
    deleteUser,
    isDeleting,
    error,
  };
};
