import { useState } from "react";
import api from "../../config/axios";
import { toastAlert } from "../../utils/toastAlert";

export const useDownloadFile = () => {
  const [isFetching, setIsFetching] = useState(false);

  const handleDownload = (id: number, view: boolean = false) => {
    setIsFetching(true);

    const queryParam = view ? `?view=true` : "";

    api
      .get(`/v1/files/get-url/id/${id}${queryParam}`)
      .then((response) => {
        const fileUrl = response.data.url;
        const fileName = response.data.name || "arquivo";



        if (fileUrl) {
          if (view) {
            // Verifica se o arquivo é .docx
            if (fileName.endsWith(".docx") || fileUrl.includes(".docx")) {
              const googleViewerUrl = `https://docs.google.com/gview?url=${encodeURIComponent(fileUrl)}&embedded=true`;

              // Configurações do pop-up
              const width = 800;
              const height = 600;
              const left = window.screenX + (window.innerWidth - width) / 2;
              const top = window.screenY + (window.innerHeight - height) / 2;

              const popup = window.open(
                googleViewerUrl,
                "_blank",
                `width=${width},height=${height},top=${top},left=${left},resizable=yes,scrollbars=yes`
              );

              if (!popup || popup.closed || typeof popup.closed === "undefined") {
                toastAlert({
                  type: "warning",
                  message: "Por favor, permita pop-ups no navegador para acessar o arquivo.",
                  title: "Pop-up bloqueado",
                });
              }
            } else {
              // Outros arquivos, abre em uma nova aba/pop-up
              const width = 800;
              const height = 600;
              const left = window.screenX + (window.innerWidth - width) / 2;
              const top = window.screenY + (window.innerHeight - height) / 2;

              const popup = window.open(
                fileUrl,
                "_blank",
                `width=${width},height=${height},top=${top},left=${left},resizable=yes,scrollbars=yes`
              );

              if (!popup || popup.closed || typeof popup.closed === "undefined") {
                toastAlert({
                  type: "warning",
                  message: "Por favor, permita pop-ups no navegador para acessar o arquivo.",
                  title: "Pop-up bloqueado",
                });
              }
            }
          } else {
            // Força o download do arquivo
            const link = document.createElement("a");
            link.href = fileUrl;
            link.download = fileName;
            link.target = "_self";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        } else {
          toastAlert({
            type: "error",
            message: "URL inválida para o download.",
            title: "Erro ao fazer download",
          });
        }
      })
      .catch((error) => {
        console.error("Erro ao buscar o arquivo:", error);
        toastAlert({
          type: "error",
          message: "Erro ao fazer download. Verifique sua conexão.",
          title: "Erro",
        });
      })
      .finally(() => setIsFetching(false));
  };

  return { handleDownload, isFetching };
};
