import React, { useState } from "react";
import { Col, Row, Typography } from "antd";
import Layout from "../../components/Template/Layout";
import CollapseCard from "../../components/Template/Card";
import UploadFile from "./components/UploadFile";
import ShowDifference from "./components/ShowDifference";
import StepsComponent from "../../components/StepsComponent";
import UpdateBank from "./components/UpdateBank";
import ShowMeasurement from "./components/ShowMeasurement";
import api from "../../config/axios"; 
import { toastAlert } from "../../utils/toastAlert";
import { calculateAppliedMeasurement } from "./Utils/calculateAppliedMeasurement";

const { Title } = Typography;

const BatchAdvance: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [excelData, setExcelData] = useState<any>(null);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [stepData, setStepData] = useState<Record<string, { percentageMeasurement: number;  excelPercent: number; remaining:number}>>({});

  const steps = [
    { title: "Carregar Arquivo", description: "Etapa para carregar arquivo", component: <UploadFile onUploadSuccess={handleUploadSuccess} /> },
    { title: "Conferência de avanço", description: "Validar o novo avanço físico", component: <ShowDifference  excelData={excelData}  onSelectRows={handleSelectRows}    onSelectionChange={(hasSelection) => setIsNextDisabled(!hasSelection)}  /> },
    { title: "Confirmação", description: "Etapa para confirmar o avanço físico", component: <UpdateBank selectedRows={selectedRows} /> },
    { title: "Solicitar Medição", description: "Etapa para solicitar o que pode ser medido", component: <ShowMeasurement steps={selectedRows.map(row => row.step)} stepData={stepData} /> }
  ];

  function handleUploadSuccess(data: any) {
    setExcelData(data);
    setCurrentStep(1);
  }


  function handleSelectRows(updatedRows: any[]) {   
  
    // Atualiza os dados validados
    const validatedRows = updatedRows.map(row => {
      const validatedPercent = calculateAppliedMeasurement(row.excelPercent.toString());
      return {
        ...row,
        excelPercent: validatedPercent,
      };
    });
  
    setSelectedRows(validatedRows);
  
   
    const newStepData = validatedRows.reduce((acc, row) => {
      acc[row.step] = {
        dbPercent: row.dbPercent,
        excelPercent: parseFloat(row.excelPercent),
        percentageMeasurement: row.percentage_Measurement, 
        remaining: row.remaining, 
        activityId: row.activity_id, 
        rules: row.rules || [], 
      };
      return acc;
    }, {} as Record<string, any>);
  
    setStepData(newStepData);
  }

  
  
  async function handleNext() {
    if (currentStep === 2) {
      const payload = selectedRows.map(row => ({
        percent_complete: row.excelPercent,
        rules: row.rules ? row.rules.map((ruleItem: { id: number }) => ruleItem.id) : [],
        code: row.step
      }));
      setLoading(true);
      try {
        const response = await api.put("v1/measurement-rules/update-measurement", payload);
        toastAlert({
          type: "success",
          message: "Atualização realizada com sucesso!",
          title: "Sucesso",
        });
        setCurrentStep(3);
      } catch (error: any) {
        toastAlert({
          type: "error",
          message: "Erro ao atualizar as medições. Tente novamente.",
          title: "Erro na Atualização",
        });
      } finally {
        setLoading(false);
      }
    }
  }
  
  return (
    <Layout pageTitle="Avanço por lote" breadcrumb="Home ⟶ Avanço por lote">
      <Row gutter={16} className="page-row">
        <Col xs={24} md={24} className="mb-2">
          <CollapseCard title="Ações">
            <Title level={2} className="total-card">
              <Col xs={24} md={24} className="mb-2">
                <StepsComponent
                  steps={steps}
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  isNextDisabled={!excelData || loading || isNextDisabled} 
                  onNext={handleNext}                
                />
              </Col>
            </Title>
          </CollapseCard>
        </Col>
      </Row>
    </Layout>
  );
};

export default BatchAdvance;
