import React from "react";
import { Select } from "antd";
import { Controller } from "react-hook-form";

const { Option } = Select;

interface FormSelectProps {
  name: string;
  label: string;
  control: any;
  options: { value: string | number; label: string }[];
  placeholder?: string;
  errors?: any;
}

const FormSelect: React.FC<FormSelectProps> = ({ name, label, control, options, placeholder, errors }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <label htmlFor={name}>{label}</label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select {...field} id={name} placeholder={placeholder} status={errors?.[name] ? "error" : ""} onChange={(value) => field.onChange(value)}>
            {options.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        )}
      />
      {errors?.[name] && <p style={{ color: "red", marginTop: "0px" }}>{errors[name].message}</p>}
    </div>
  );
};

export default FormSelect;
