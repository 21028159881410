import React, { useMemo, useState } from "react";
import { Table, Button, Modal } from "antd";
import { useFetchMeasurementData } from "../../../hooks/activity/useFetchMeasurementData";
import { toastAlert } from "../../../utils/toastAlert";
import api from "../../../config/axios";
import { useNavigate } from "react-router-dom";
import { CloudUploadOutlined } from "@ant-design/icons";
import FileUploadModalContent from "./FileUploadModalContent";
import { useFetchFilesForActivities } from "../../../hooks/files/useFetchFilesForActivities";
import { calculateAppliedMeasurement } from "../Utils/calculateAppliedMeasurement";

type ShowMeasurementProps = {
  steps: string[];
  stepData: Record<string, {  percentageMeasurement: number;  excelPercent: number; remaining:number }>;
};

const ShowMeasurement: React.FC<ShowMeasurementProps> = ({ steps, stepData }) => {

  const navigate = useNavigate();
  const { data, loading } = useFetchMeasurementData(steps);
  const [selectedRowsKeys, setSelectedRowsKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedActivityId, setSelectedActivityId] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Estado local para contagem de arquivos (atualizável após interações no modal)
  const [dynamicFileCounts, setDynamicFileCounts] = useState<Record<string, number>>({});

  const activityIds = useMemo(() => {
    return data?.map((item: any) => item.id) || [];
  }, [data]);

  // Contagens iniciais de arquivos
  const { fileCounts, isLoading: isLoadingFiles } = useFetchFilesForActivities({
    activity_ids: activityIds,
  });

  // Atualizar contagem de arquivos dinamicamente após interações no modal
  const updateFileCount = (activityId: string, newCount: number) => {
    setDynamicFileCounts((prev) => ({
      ...prev,
      [activityId]: newCount,
    }));
  };



  const closeModal = () => {
    setSelectedActivityId(null);
    setIsModalOpen(false);
  };

  const filteredData = data?.filter((item: any) => {
    const percentageMeasurement = stepData[item.step]?.percentageMeasurement ?? 0;

    const excelPercent = stepData[item.step]?.excelPercent ?? 0;

    // Calcula a medição aplicável com base na regra
    const appliedMeasurement = calculateAppliedMeasurement(excelPercent.toString());

    // Valida se a solicitação é válida (se for maior do que o já medido)
    const isValidRequest = parseFloat(appliedMeasurement) > percentageMeasurement;

    return item.can_measurement && isValidRequest; // Inclui apenas se for uma medição válida
  }) || [];

  const openModal = (activityId: string) => {
    setSelectedActivityId(activityId);
    setIsModalOpen(true);
  };

  const dataSource = filteredData.map((item: any, index: number) => {    
    const fileCount = dynamicFileCounts[item.id] ?? fileCounts[item.id] ?? 0; // Prioriza contagem dinâmica
    return {
      key: item.id || index,
      actions: (
        <Button
          icon={
            fileCount > 0 ? (
              <span>{fileCount} 📁</span>
            ) : (
              <CloudUploadOutlined />
            )
          }
          onClick={() => openModal(item.id)}
        >
          {fileCount > 0 ? "Arquivos anexados" : "Anexar arquivos"}
        </Button>
      ),
      ...item,
    };
  });
  const columns = [
    {
      title: "Step",
      dataIndex: "step",
      key: "step",
      width: 150,
    },
    {
      title: "Medição já realizada",
      dataIndex: "step",
      key: "percentage_Measurement",
      width: 300,
      render: (step: string) => {
        const dbPercent = stepData[step]?.percentageMeasurement ?? "N/A";
        return dbPercent + "%";
      },
    },
    {
      title: "Nova Solicitação",
      dataIndex: "step",
      key: "new_request",
      width: 300,
      render: (step: string) => {
        const excelPercent = stepData[step]?.excelPercent ?? "N/A";
        return excelPercent + "%";
      },
    },
    {
      title: "Medição Disponível",
      dataIndex: "step",
      key: "available_measurement",
      width: 300,
      render: (step: string) => {
        const percentageMeasurement = stepData[step]?.percentageMeasurement ?? 0; // Medição já realizada
        const percentage_requested = stepData[step]?.excelPercent   ?? 0; // Valor solicitado
    
    
        // Calcula a medição real solicitada
        const realRequest = percentageMeasurement === 0
          ? percentage_requested
          : Math.max(0, percentage_requested - percentageMeasurement); // Garante que o valor não seja negativo
    
        // Calcula o restante disponível
        const remainingMeasurement = percentageMeasurement === 0
          ? 100
          : Math.max(0, 100 - percentageMeasurement); // Garante que o valor não seja negativo
    
        return `solicitando ${realRequest}%,   disponivel total (${remainingMeasurement}%)`;
      },
    },
    {
      title: "",
      dataIndex: "actions",
      width: 173,
      key: "actions",
    },
  ];

  const handleUpdate = async () => {
    setIsSubmitting(true);
    const payload = {
      data: selectedRows.map((row) => {
        const percentComplete = row.remaining_measurement?.percent_complete || "0";
        const remaining = calculateAppliedMeasurement(percentComplete); // Aplica a regra
        return {
          id: row.id,
          remaining,
        };
      }),
    };



    try {
      const response = await api.post("v1/measurement-partials/update-data", payload);
      const { data, success, message } = response.data;

      const successfulItems = data.filter((item: any) => item.status === "success");
      const failedItems = data.filter((item: any) => item.status === "error");

      toastAlert({
        type: success ? "success" : "warning",
        message: message,
        title: success ? "Sucesso" : "Atenção",
      });

      if (failedItems.length > 0) {
        toastAlert({
          type: "error",
          message: `${failedItems.length} itens falharam durante atualização`,
          title: "Falha na Atualização",
        });
      } else {
        // Redireciona para a rota "/control" com a aba de medições selecionada
        navigate("/control", { state: { defaultType: "measurement-partials" } });
      }
    } catch (error: any) {
      toastAlert({
        type: "error",
        message: "Erro ao atualizar os dados. Tente novamente.",
        title: "Erro na Atualização",
      });
      console.error("Erro ao enviar os dados:", error);
    }
    finally {
      setIsSubmitting(false);
    }
  };

  const rowSelection = {
    selectedRowKeys: selectedRowsKeys, // Sincroniza as chaves selecionadas
    columnWidth: 50,
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRowsKeys(selectedRowKeys); // Atualiza as chaves selecionadas
      setSelectedRows(selectedRows); // Atualiza os dados das linhas selecionadas
    },
  };

  return (
    <div>
      <div className="selected-items-counter">
        Itens Selecionados: {selectedRowsKeys.length}
      </div>
      <div className="container-difference">
        <div className="table-wrapper">
          <Table
            rowSelection={{ type: "checkbox", ...rowSelection }}

            columns={columns}
            dataSource={dataSource}
            loading={loading || isLoadingFiles}
            pagination={false}
            bordered
            scroll={{ x: "max-content", y: 600 }}
          />
        </div>
      </div>
      <div style={{ marginTop: "20px", justifyContent: "center", display: "flex" }}>
        <Button type="primary" onClick={handleUpdate} disabled={selectedRows.length === 0} loading={isSubmitting}>
          Solicitar
        </Button>
        <Modal title="Anexar Arquivos" open={isModalOpen} onCancel={closeModal} footer={null} className="file-upload-modal">
          <FileUploadModalContent
            activityId={selectedActivityId}
            onFileListUpdate={(files) => updateFileCount(selectedActivityId!, files.length)}
          />
        </Modal>
      </div>
    </div>
  );
};

export default ShowMeasurement;
