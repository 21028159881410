import { useState } from "react";
import api from "../../../config/axios";
import { EditUserFormData } from "../validation/editUserSchema";
import { toastAlert } from "../../../utils/toastAlert";

interface UseUpdateUserResponse {
  updateUser: (id: number, data: EditUserFormData) => Promise<void>;
  isUpdating: boolean;
  error: Error | null;
}

export const useUpdateUser = (): UseUpdateUserResponse => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const updateUser = async (id: number, data: EditUserFormData) => {
    setIsUpdating(true);
    setError(null);

    try {
      const response = await api.put(`/v1/users/${id}`, data);
      toastAlert({
        type: "success",
        message: "As informações do usuário foram atualziadas com sucesso",
        title: "Usuário atualizado",
      });
    } catch (err: any) {
      toastAlert({
        type: "error",
        message: "Não foi possível atualizar usuário",
        title: "Erro ao atualizar usuário",
      });
      setError(err);
    } finally {
      setIsUpdating(false);
    }
  };

  return {
    updateUser,
    isUpdating,
    error,
  };
};
