import React, { useState, useEffect } from "react";
import { Table, Select } from "antd";
import {
  ArrowRightOutlined,
  CheckCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import api from "../../../config/axios";
import "../BatchAdvance.scss";

type ShowDifferenceProps = {
  excelData: any;
  onSelectRows: (selectedRows: any[]) => void;
  onSelectionChange: (hasSelection: boolean) => void;
};

interface RuleType {
  id: number;
  title: string;
}

interface RowType {
  key: number;
  step: string;
  excelPercent: number;
  dbPercent: number;
  activity_id: string | null;
  rules?: RuleType[];
}

const ShowDifference: React.FC<ShowDifferenceProps> = ({
  excelData,
  onSelectRows,
  onSelectionChange,
}) => {
  const { excel_data, database_data } = excelData;

  const [validationData, setValidationData] = useState<{
    [key: string]: RuleType[];
  }>({});
  const [selectedRules, setSelectedRules] = useState<{
    [key: string]: RuleType[];
  }>({});
  const [selectedRowsKeys, setSelectedRowsKeys] = useState<React.Key[]>([]);
  const [selectedRowsData, setSelectedRowsData] = useState<RowType[]>([]);
  const [loadingSteps, setLoadingSteps] = useState<{ [key: string]: boolean }>({});



  useEffect(() => {
    const fetchValidations = async () => {
      try {
        const response = await api.get(
          "v1/validation-evidence/activity-validations-with-evidences"
        );
        const validations = response.data;

        const mappedValidations: { [key: string]: RuleType[] } = {};
        validations.forEach((validation: any) => {
          mappedValidations[validation.activityCode] =
            validation.validationEvidences.map((evidence: any) => ({
              id: evidence.id,
              title: evidence.title,
            }));
        });

        setValidationData(mappedValidations);
        setSelectedRules(mappedValidations); // Preenche os `Selects` com as regras existentes
      } catch (error) {
        console.error("Erro ao carregar validações:", error);
      }
    };

    fetchValidations();
  }, []);

  const handleSelectClick = (step: string) => {
    setLoadingSteps((prev) => ({ ...prev, [step]: true }));

    api
      .get(`v1/measurement-rules/fetch-rules/${step}`)
      .then((response) => {
        const rules = response.data.map(
          (rule: { id: number; title: string }) => ({
            id: rule.id,
            title: rule.title,
          })
        );

        setValidationData((prev) => {
          const existingRules: RuleType[] = prev[step] || [];
          const combinedRules: RuleType[] = [
            ...existingRules,
            ...rules.filter(
              (newRule: RuleType) =>
                !existingRules.some((rule) => rule.id === newRule.id)
            ),
          ];
          return { ...prev, [step]: combinedRules };
        });
      })
      .catch((error) => {
        console.error("Erro ao buscar regras:", error);
      })
      .finally(() => {
        setLoadingSteps((prev) => ({ ...prev, [step]: false }));
      });
  };

  const handleRuleChange = (
    values: React.Key[],
    stepRules: RuleType[],
    step: string
  ) => {
    const selectedRulesArray = values
      .map((id) => {
        const rule = stepRules.find((rule) => rule.id === id);
        return rule ? { id: rule.id, title: rule.title } : null;
      })
      .filter((rule) => rule !== null) as RuleType[];

    setSelectedRules((prev) => ({
      ...prev,
      [step]: selectedRulesArray,
    }));

    // Atualiza as regras apenas para as linhas selecionadas
    setSelectedRowsData((prev) =>
      prev.map((row) =>
        row.step === step ? { ...row, rules: selectedRulesArray } : row
      )
    );

    // Chama onSelectRows com as linhas selecionadas atualizadas
    onSelectRows(
      selectedRowsData.map((row) =>
        row.step === step ? { ...row, rules: selectedRulesArray } : row
      )
    );
  };

  const dataSource: RowType[] = excel_data.slice(1).map((row: any) => {
    const code = row.step; 
    const excelPercent = row.value * 100; 
    const dbData = database_data.find((data: any) => data.code === code);
    const remaining = dbData?.remaining_measurement?.remaining || 0;
    const percentage_Measurement = dbData?.remaining_measurement?.percentage_Measurement || 0;
    const dbPercent = dbData ? parseFloat(dbData.percent_complete) : 0;
    const activity_id = dbData ? dbData.activity_id : null;

    return {
      key: row.id, //  `id` retornado pelo Excel para a ordenação
      step: code,
      excelPercent,
      dbPercent,
      activity_id,
      remaining,
      percentage_Measurement,
      rules: validationData[code] || [],
    };
  })
    .filter(
      (row: RowType) =>
        row.dbPercent !== 100 &&
        row.excelPercent !== row.dbPercent &&
        row.excelPercent !== 0
    );

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: RowType[]) => {
      const selectedData = selectedRows.map((row) => ({
        ...row,
        rules: selectedRules[row.step] || [],
      }));


      setSelectedRowsData(selectedData);
      onSelectRows(selectedData); // Passa apenas as linhas selecionadas
      setSelectedRowsKeys(selectedRowKeys);

      onSelectionChange(selectedRows.length > 0);
    },
    getCheckboxProps: (record: RowType) => ({
      disabled: record.dbPercent > record.excelPercent,
    }),
  };

  const columns = [
    {
      title: "Step",
      dataIndex: "step",
      key: "step",
      width: 200,
    },
    {
      title: "Atualização do avanço físico atual.",
      dataIndex: "percent_change",
      key: "percent_change",
      width: 200,
      render: (_: any, record: RowType) => {
        const isDifferent = record.excelPercent !== record.dbPercent;
        return (
          <span className="percent-change-cell">
            {record.dbPercent}%
            <ArrowRightOutlined
              className={`arrow-icon ${isDifferent ? "green-arrow" : "gray-arrow"
                }`}
            />
            {record.excelPercent}%
            {isDifferent ? (
              <QuestionCircleOutlined className="question-icon" />
            ) : (
              <CheckCircleOutlined className="check-icon" />
            )}
          </span>
        );
      },
    },
    {
      title: "Regras de validação de evidência",
      dataIndex: "rules",
      key: "rules",
      width: 200,
      render: (_: any, record: RowType) => {
        const stepRules = validationData[record.step] || [];
        const isLoading = loadingSteps[record.step] || false;

        return (
          <Select
            mode="multiple"
            placeholder={isLoading ? "Carregando..." : "Selecione uma regra"}
            style={{ width: "100%" }}
            options={stepRules.map((rule) => ({
              value: rule.id,
              label: rule.title,
            }))}
            onClick={() => handleSelectClick(record.step)}
            onChange={(values) =>
              handleRuleChange(values, stepRules, record.step)
            }
            value={selectedRules[record.step]?.map((rule) => rule.id) || []}
            loading={isLoading}
          />
        );
      },
    },
  ];

  return (
    <div className="container-difference">
      <div className="table-wrapper">
        <Table
          rowSelection={{ type: "checkbox", ...rowSelection }}
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          bordered
          scroll={{ x: "max-content", y: 600 }}
          rowClassName={(record) =>
             record.dbPercent > record.excelPercent ? "disabled-row" : ""
          }
        />
      </div>
    </div>
  );
};

export default ShowDifference;
