import { useState } from "react";
import { Select, Table, Modal, Popover } from "antd";
import { RotateRightOutlined } from "@ant-design/icons";
import "./SettingsPage.scss";
import { useFetchContracts } from "../../hooks/contracts/useFetchContracts";
import api from "../../config/axios";
import { toastAlert } from "../../utils/toastAlert";


interface Iwp {
    id: string;
    name: string;
    unique: boolean;
}

export default function IwpFlagSettings() {
    const { contracts } = useFetchContracts({
        current: 1,
    });

    const [selectedContract, setSelectedContract] = useState<number | null>(null);
    const [loading, setLoading] = useState(false);
    const [iwps, setIwps] = useState<Iwp[]>([]);
    const [selectedIwpId, setSelectedIwpId] = useState<string | null>(null);
    const [popoverVisible, setPopoverVisible] = useState<string | null>(null);

    const handleContractChange = async (contractId: number) => {
        setLoading(true);
        try {
            const response = await api.get(`v1/iwps/search-iwps`, {
                params: { contract_id: contractId },
            });
            setIwps(response.data.data || []);
        } catch (error) {
            console.error("Erro ao buscar IWPs:", error);
        } finally {
            setLoading(false);
        }
    };
    const handleConfirmUnique = async () => {
        if (!selectedIwpId) return;

        try {
            // Encontra o registro do IWP com base no ID selecionado
            const iwpToUpdate = iwps.find((iwp: any) => iwp.id === selectedIwpId);

            if (!iwpToUpdate) {
                console.error("IWP não encontrado.");
                return;
            }

            // Define o novo valor para o campo `unique`
            const updatedUniqueValue = !iwpToUpdate.unique;

            // Chama a API para atualizar o campo `unique`
            await api.put(`v1/iwps/${selectedIwpId}`, {
                unique: updatedUniqueValue,
            });

            // Atualiza a lista de IWPs no estado local para refletir a mudança
            setIwps((prevIwps) =>
                prevIwps.map((iwp: any) =>
                    iwp.id === selectedIwpId ? { ...iwp, unique: updatedUniqueValue } : iwp
                )
            );
            toastAlert({
                type: "success",
                message: `IWP ID: ${selectedIwpId} foi atualizado com sucesso.`,
                title: "Atualizado com Sucesso!",
            });

        } catch (error) {
            toastAlert({
                type: "error",
                message: "Ocorreu um erro ao tentar atualizar o IWP.",
                title: "Erro!",
            });

        } finally {
            // Fecha o popover
            setPopoverVisible(null);
        }
    };


    const columns = [
        {
            title: "Nome Iwp",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Permitido sub Atividades?",
            dataIndex: "unique",
            key: "unique",
            render: (unique: boolean) => (unique ? "Não" : "Sim"),
        },
        {
            title: "Ações",
            key: "actions",
            render: (_: any, record: any) => (
                <Popover
                    content={
                        <div>
                            {record.unique ? (
                                <p>Você deseja mudar este IWP para não permitir sub atividades?</p>
                            ) : (
                                <p>Você deseja mudar este IWP para permitir sub atividades?</p>
                            )}
                            <div style={{ textAlign: "right" }}>
                                <button
                                    style={{
                                        marginRight: "8px",
                                        border: "none",
                                        background: "transparent",
                                        color: "red",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => setPopoverVisible(null)}
                                >
                                    Não
                                </button>
                                <button
                                    style={{
                                        border: "none",
                                        background: "#1890ff",
                                        color: "white",
                                        cursor: "pointer",
                                        padding: "4px 8px",
                                        borderRadius: "4px",
                                    }}
                                    onClick={() => handleConfirmUnique()}
                                >
                                    Sim
                                </button>
                            </div>
                        </div>
                    }
                    trigger="click"
                    visible={popoverVisible === record.id}
                    onVisibleChange={(visible) => {
                        setPopoverVisible(visible ? record.id : null);
                        setSelectedIwpId(record.id);
                    }}
                >
                    <RotateRightOutlined
                        style={{ fontSize: "18px", cursor: "pointer", color: "#1890ff" }}
                    />
                </Popover>
            ),
        },
    ];

    return (
        <div>
            <div className="iwp-flag-settings">
                <Select
                    value={selectedContract}
                    placeholder="Selecione o contrato"
                    style={{ width: "100%", marginBottom: "16px" }}
                    onChange={(value) => {
                        setSelectedContract(value);
                        handleContractChange(value);
                    }}
                    options={contracts?.map((item: any) => ({
                        value: item.id,
                        label: item.description,
                    }))}
                />
            </div>

            {iwps.length > 0 && (
                <Table
                    dataSource={iwps}
                    columns={columns}
                    rowKey="id"
                    loading={loading}
                    bordered
                    pagination={false}
                />
            )}

        </div>
    );
}
