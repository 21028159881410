import { useCallback, useEffect, useState } from "react";
import api from "../../../config/axios";
import { User } from "../../../models/User";

type Props = {
  search?: string;
  page?: number;
  refresh?: Date;
};
export const useFetchUsers = ({ search, page, refresh }: Props) => {
  const [users, setUsers] = useState<User[]>([]);
  const [total, setTotal] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchUsers = useCallback(() => {
    setIsFetching(true);
    api
      .get(`/v1/users?page=${page}`, {
        params: search ? { search } : {},
      })
      .then((response) => {
        setUsers(response.data.users.data);
        setTotal(response.data.users.total);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [search, page, refresh]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return {
    users,
    total,
    isFetching,
    error,
  };
};
