import { Button, Tooltip } from "antd";
import React, { Key } from "react";
import { NavigateFunction } from "react-router-dom";
import api from "../../../../services/Api";
import { TableType } from "../../../../types/cwa";
import { NavigateButton } from "../../components/NavigateButton";
import { navigateToActivities, navigateToWP } from "../navigate";
import { BankOutlined} from "@ant-design/icons";

export const setMainTableData = ({
  setTables,
  project_id,
  selectedPage,
  navigate,
  setPageSize,
}: {
  setTables: React.Dispatch<React.SetStateAction<TableType>>;
  project_id: string | undefined;
  selectedPage: number;
  navigate: NavigateFunction;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
}) => {
  setTables((prevState) => ({
    ...prevState,
    table1: { ...prevState.table1, isLoading: true },
  }));

  api.get(`/v1/projects/${project_id}/cwas?page=${selectedPage}`).then((response) => {
    if (response.status === 200) {
      const data = response.data.data;
      const table = data.map((obj: any) => ({
        ...obj,
        key: obj.id,
        actions: (
          <NavigateButton
            navFunction={navigateToWP}
            funcProps={{
              cwaId: obj.id,
              navigate,
              project_id,
            }}
            name="WPs"
          />
        ),
      }));
      setPageSize(response.data.per_page);
      setTables((prevState) => ({
        ...prevState,
        table1: {
          ...prevState.table1,
          data: table,
          total: response.data.total,
        },
      }));
    }

    setTables((prevState) => ({
      ...prevState,
      table1: { ...prevState.table1, isLoading: false },
    }));
  });
};

export const setMainTableDataWithSearch = ({
  setTables,
  project_id,
  search,
  selectedPage,
  navigate,
  setPageSize,
}: {
  setTables: React.Dispatch<React.SetStateAction<TableType>>;
  project_id: string | undefined;
  search?: string | number;
  selectedPage: number;
  navigate: NavigateFunction;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
}) => {
  setTables((prevState) => ({
    ...prevState,
    table1: { ...prevState.table1, isLoading: true },
  }));
  if (search !== "") {
    api.post(`/v1/projects/${project_id}/cwas/search`, { search: search }).then((response) => {
      const data = response.data;
      const table = data.map((obj: any) => ({
        ...obj,
        key: obj.id,
        actions: (
          <NavigateButton
            navFunction={navigateToWP}
            funcProps={{
              cwaId: obj.id,
              navigate,
              project_id,
            }}
            name="WPs"
          />
        ),
      }));
      setPageSize(data.length);
      setTables((prevState) => ({
        ...prevState,
        table1: {
          ...prevState.table1,
          data: table,
          total: data.length,
        },
      }));

      setTables((prevState) => ({
        ...prevState,
        table1: { ...prevState.table1, isLoading: false },
      }));
    });
  } else {
    api.get(`/v1/projects/${project_id}/cwas?page=${selectedPage}`).then((response) => {
      if (response.status === 200) {
        const data = response.data.data;
        const table = data.map((obj: any) => ({
          ...obj,
          key: obj.id,
          actions: (
            <NavigateButton
              navFunction={navigateToWP}
              funcProps={{
                cwaId: obj.id,
                navigate,
                project_id,
              }}
              name="WPs"
            />
          ),
        }));
        setPageSize(response.data.per_page);
        setTables((prevState) => ({
          ...prevState,
          table1: {
            ...prevState.table1,
            data: table,
            total: response.data.total,
          },
        }));
      }

      setTables((prevState) => ({
        ...prevState,
        table1: { ...prevState.table1, isLoading: false },
      }));
    });
  }
};

export const setExpandedData1 = async ({
  key,
  page,
  navigate,
  project_id,
  setTables,
}: {
  key: Key;
  page?: number | undefined;
  navigate: NavigateFunction;
  project_id: string | undefined;
  setTables: React.Dispatch<React.SetStateAction<TableType>>;
}) => {
  if (!key) return false;
  setTables((prevState) => ({
    ...prevState,
    table2: { ...prevState.table2, isLoading: true, isLoadingId: key },
  }));

  api.get("/v1/cwas/" + key + "/wps?page=" + (page || 1)).then((response) => {
    // setFetchingData2(truepage?: number | undefined)
    if (response?.status === 200) {
      const data1 = response.data.data.map((obj: any) => ({
        ...obj,
        key: obj.id,
        description: obj.description,
        subdisciplina: obj.sub_discipline_name,
        area: "Geral",
        state: "Finalizado",
        actions: <NavigateButton navFunction={navigateToActivities} funcProps={{ wpId: obj.id, navigate, project_id }} name="atividades" />,
      }));
      setTables((prevState) => ({
        ...prevState,
        table2: {
          ...prevState.table2,
          data: { ...prevState.table2.data, [key]: data1 },
          total: response.data.total,
        },
      }));
    }

    setTables((prevState) => ({
      ...prevState,
      table2: { ...prevState.table2, isLoading: false, isLoadingId: null },
    }));
  });
};

export const setExpandedData2 = async ({ key, setTables }: { key: Key; setTables: React.Dispatch<React.SetStateAction<TableType>> }) => {
  const groupByKey = (list: any, key: any) =>
    (list || []).reduce(
      (hash: any, obj: any) => ({
        ...hash,
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      }),
      {}
    );

  if (!key) return false;

  setTables((prevState) => ({
    ...prevState,
    table3: { ...prevState.table3, isLoading: true, isLoadingId: key },
  }));

  await api.get(`v1/wps/${key}/activities`).then((response) => {
    if (response?.status === 200) {
      const activities = response.data.activities;
      const iwps = response?.data?.iwps;
      const concattedData = activities.data.concat(iwps);

      const types = Object.keys(groupByKey(concattedData, "type"));
      const iwp = Object.keys(groupByKey(concattedData.data, "cwp_id"));

      let data2: any = [];
      if (types.includes("EWP"))
        data2.push({
          key: key + "-ewp",
          content: "Engenharia",
          type: "EWP",
        });
      if (types.includes("PWP"))
        data2.push({
          key: key + "-pwp",
          content: "Fornecimento",
          type: "PWP",
        });
      if (types.includes("CWP"))
        data2.push({
          key: key + "-cwp",
          content: "Construção",
          type: "CWP",
        });
      if (!!iwp)
        data2.push({
          key: key + "-iwp",
          content: "IWPS",
          type: "IWP",
        });

      setTables((prevState) => ({
        ...prevState,
        table3: {
          ...prevState.table3,
          data: { ...prevState.table3.data, [key]: data2 },
          total: response.data.total,
        },
      }));
    }

    setTables((prevState) => ({
      ...prevState,
      table3: { ...prevState.table3, isLoading: false, isLoadingId: null },
    }));
  });
};

export const setExpandedData3 = async ({
  key,
  navigate,
  setTables,
  openModal,
}: {
  key: Key;
  navigate: NavigateFunction;
  setTables: React.Dispatch<React.SetStateAction<TableType>>;
  openModal: (activity: any) => void;
}) => {
  if (!key) return false;

  const arr = key.toString().split("-");

  if (arr[1] === "iwp") {
    setTables((prevState) => ({
      ...prevState,
      table4: { ...prevState.table4, isLoading: true, isLoadingId: key },
    }));

    await api.get(`/v1/iwps/cwp/${arr[0]}?all=true`).then((response) => {
      
      const data = response.data.data.map((obj: any) => ({
        
        key: obj.id,
        content: obj.name,
        description: obj.description,
        percentual_complete: obj.percentual_complete,
        unique: obj.unique,
        actions: obj.unique ? (
          // Botão aparece diretamente na Table4 quando unique é true
          <Button
            size="small"
            type="primary"
            onClick={() =>
            
              navigate(`/activity/${obj.activity_id}`, {
                state: { isFromProject: true },
              })
            }
          >
            Abrir atividade
          </Button>
        ) : null, // Caso contrário, o botão será tratado na Table5
      }));

      setTables((prevState) => ({
        ...prevState,
        table4: {
          ...prevState.table4,
          data: { ...prevState.table4.data, [key]: data },
          total: response.data.total,
        },
      }));

      setTables((prevState) => ({
        ...prevState,
        table4: { ...prevState.table4, isLoading: false, isLoadingId: null },
      }));
    });
  } else {
    setTables((prevState) => ({
      ...prevState,
      table4: { ...prevState.table4, isLoading: true, isLoadingId: key },
    }));

    await api.get(`/v1/wps/${arr[0]}/activities/${arr[1]}`).then((response) => {
      const activities = response.data.activities;
      const iwps = response?.data?.iwps;
      const concattedData = activities.data.concat(iwps);

      const data = concattedData.map((obj: any) => ({
        key: obj.id,
        status: obj.status,
        content: obj.name,
        actions: (
          <Button
            size="small"
            type="primary"
            onClick={() =>
              navigate(`/activity/${obj.id}`, {
                state: { isFromProject: true },
              })
            }
          >
            Abrir atividade
          </Button>
        ),
      }));
      setTables((prevState) => ({
        ...prevState,
        table4: {
          ...prevState.table4,
          data: { ...prevState.table4.data, [key]: data },
          total: response.data.total,
        },
      }));

      setTables((prevState) => ({
        ...prevState,
        table4: { ...prevState.table4, isLoading: false, isLoadingId: null },
      }));
    });
  }
};


export const setExpandedDataIWP = async ({
  key,
  navigate,
  setTables,
  openModal,
}: {
  key: Key;
  navigate: NavigateFunction;
  setTables: React.Dispatch<React.SetStateAction<TableType>>;
  openModal: (activity: any) => void;
}) => {
  if (!key) return false;

  setTables((prevState) => ({
    ...prevState,
    table5: { ...prevState.table5, isLoading: true, isLoadingId: key },
  }));

  await api.get(`/v1/activity/iwp/${key}`).then((response) => {
    const data = response.data.data.map((obj: any) => {    
      // Encontre o maior percentage dentro de `measure` ou `partials_measurements`
      const partialsMeasurementsPercentages = obj.partials_measurements?.map((partial: any) => parseFloat(partial.percentage || "0")) || [];
      const measurePercentage = parseFloat(obj.measure?.percentage || "0");

      const highestPercentage = Math.max(measurePercentage, ...partialsMeasurementsPercentages);

      return {
        key: obj.id,
        name: obj.name,
        percentual_group_category: obj.percentual_group?.category || "--",
        code: obj.code,
        percent_complete: `${highestPercentage.toFixed(2)}%`, // Formata como string com `%`
        status: obj.status?.name || "Sem Status",
        can_measurement: obj.can_measurement,
        actions: (
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            {obj.can_measurement ? (
              <Tooltip title="Solicitar Medição">
                <BankOutlined
                  style={{ fontSize: "20px", color: "grey", cursor: "pointer" }}
                  onClick={() => openModal(obj)} // Chama o openModal com a atividade
                />
              </Tooltip>
            ) : (
              ""
            )}
            <Button
              size="small"
              type="primary"
              onClick={() =>
                navigate("/activity/" + obj.id, {
                  state: { isFromProject: false },
                })
              }
            >
              Abrir atividade
            </Button>
          </div>
        ),
      };
    });

    setTables((prevState) => ({
      ...prevState,
      table5: {
        ...prevState.table5,
        data: { ...prevState.table5.data, [key]: data },
        total: response.data.total,
      },
    }));

    setTables((prevState) => ({
      ...prevState,
      table5: { ...prevState.table5, isLoading: false, isLoadingId: null },
    }));
  });
};
