import { useState } from "react";
import api from "../../../config/axios";
import { CreateUserFormData } from "../validation/createUserSchema";
import { toastAlert } from "../../../utils/toastAlert";

interface UseCreateUserResponse {
  createUser: (data: CreateUserFormData) => Promise<void>;
  isCreating: boolean;
  error: Error | null;
}

export const useCreateUser = (): UseCreateUserResponse => {
  const [isCreating, setIsCreating] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const createUser = async (data: CreateUserFormData) => {
    setIsCreating(true);
    setError(null);

    try {
      const response = await api.post("/v1/users", data);
      toastAlert({
        type: "success",
        message: "O usuário foi criado com sucesso e ja pode acessar o sistema",
        title: "Usuário criado",
      });
    } catch (err: any) {
      toastAlert({
        type: "error",
        message: "O usuário não foir criado, tente novamente",
        title: "Erro ao criar usuário",
      });
      setError(err);
    } finally {
      setIsCreating(false);
    }
  };

  return {
    createUser,
    isCreating,
    error,
  };
};
