import { useCallback, useEffect, useState } from "react";
import api from "../../config/axios";

export interface Role {
  id: number;
  name: string;
}

export const useFetchTenants = () => {
  const [tenants, setTenants] = useState<Role[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchRoles = useCallback(() => {
    setIsFetching(true);
    api
      .get(`/v1/roles/tenants`)
      .then((response) => {
        setTenants(response.data);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, []);

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  return {
    tenants,
    isFetching,
    error,
  };
};
