import { Button, Col, Pagination, Popconfirm, Row, Table, Typography } from "antd";
import Layout from "../../components/Template/Layout";

import columns from "./constants/columns";
import CollapseCard from "../../components/Template/Card";
import { useState } from "react";
import { PaginationProps } from "antd/lib/pagination";
import SearchInput from "../../components/SearchInput";
import { useFetchUsers } from "./fetch/useFetchUsers";
import { FaEdit, FaTrash } from "react-icons/fa";
import ModalCreateEditUser from "./components/ModalCreateEditUser/ModalCreateEditUser";
import { useDeleteUser } from "./fetch/useDeleteUser";
const { Title } = Typography;

export default function UsersPage() {
  const [current, setCurrent] = useState(1);

  const [userSearch, setUserSearch] = useState("");
  const [search, setSearch] = useState("");
  const [id, setId] = useState();
  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState<Date>();
  const { deleteUser } = useDeleteUser();

  const { users, total } = useFetchUsers({ search, page: current, refresh });
  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserSearch(e.target.value);
  };
  const onChange: PaginationProps["onChange"] = (page) => {
    setCurrent(page);
  };
  const handleOpenModal = (id?: any) => {
    if (id) setId(id);

    setOpen(true);
  };
  const onCloseModal = () => {
    setRefresh(new Date());
    setId(undefined);
    setOpen(false);
  };

  return (
    <Layout pageTitle="Usuários" breadcrumb="Home > Usuários">
      <Row gutter={16} className="page-row">
        <Col xs={24} md={6} className="mb-2">
          <CollapseCard title="Total">
            <Title id="activities-total" level={2} className="total-card">
              {total}
            </Title>
          </CollapseCard>
        </Col>
        <Col xs={24} md={18} className="mb-2">
          <CollapseCard title="Ações">
            <Col span={24} className="mb-2 activity-filters">
              <Button onClick={() => handleOpenModal()}> Criar Usuário</Button>
              <div className="container-search">
                <SearchInput
                  type="text"
                  handleChange={handleChangeSearch}
                  value={userSearch}
                  handleSearch={() => {
                    setSearch(userSearch);
                  }}
                  loading={false}
                  placeholder="Busque por um nome"
                />
              </div>
            </Col>
          </CollapseCard>
        </Col>

        <Col xs={24} md={24}>
          <CollapseCard title="Relatórios" className="card-table-no-padding">
            <Row>
              <div className="table-report-container">
                <Table
                  className="table-report"
                  columns={columns}
                  dataSource={users.map((item: any) => ({
                    ...item,
                    actions:
                      item?.role?.length > 0 ? (
                        <div style={{ display: "flex", gap: "3px" }}>
                          <Button type="primary" className="wp-actions-btn" onClick={() => handleOpenModal(item.id)} id="edit-wp">
                            <FaEdit />
                          </Button>
                          <Popconfirm
                            title="Excluir usuário"
                            description="Tem certeza que quer exlcuir esse usuário? é uma ação irreversível!"
                            okText="Sim"
                            cancelText="Não"
                            onConfirm={() => {
                              deleteUser(item.id);
                              setRefresh(new Date());
                            }}
                          >
                            <Button type="primary" className="wp-actions-btn" id="edit-wp">
                              <FaTrash />
                            </Button>
                          </Popconfirm>
                        </div>
                      ) : (
                        <>-</>
                      ),
                  }))}
                  pagination={false}
                  loading={false}
                  size="large"
                  scroll={{ x: 1000 }}
                  locale={{ emptyText: "Sem dados" }}
                />
              </div>

              <Col
                span={24}
                className="text-center mt-4"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "5px",
                }}
              >
                <Pagination current={current} onChange={onChange} total={total} pageSize={30} showSizeChanger={false} />
              </Col>
            </Row>
          </CollapseCard>
        </Col>
      </Row>
      {!!open && <ModalCreateEditUser isModalOpen={open} id={id} onClose={onCloseModal} />}
    </Layout>
  );
}
